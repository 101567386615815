import { useStaticQuery, graphql } from "gatsby"

export const useThreeQuery = () => {
  const data = useStaticQuery(graphql`
    query ThreeQuery {
      wpPage(databaseId: {eq: 185}) {
        id
        ACF_Homepage {
          threeImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, placeholder: BLURRED)
              }
            }
          }
          threeLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 365, placeholder: BLURRED)
              }
            }
          }
          threeText
          threeList {
            listItem
          }
        }
      }
    }
  `)

  return data
}
