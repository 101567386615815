import styled from "styled-components"

import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  margin-top: -0.75rem;
`

export const Wrapper = styled.div`
  background: #000;
  color: #fff;
  position: relative;
  padding: 4rem 0;
  @media screen and (max-width:767px) {
    padding-bottom: 0.5rem;
  }
  .provideMainText {
    text-align: center;
    padding-bottom: 2rem;
    h2 {
      margin-bottom: 0.5rem;
    }
    p {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px;
      color: #CECECE;
    }
  }
  .provideRow {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .title-text-tags-wrapper{
      margin-top: 0;
      border-top: solid 1px #fff;
      padding-top: 1rem;
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        h3 {
          width: 100%!important;
          padding-right: 0.75rem!important;
          padding-bottom: 0!important;
          font-size: 36px!important;
        }
        .text-tags {
          width: 100%!important;
          .provideText {
            padding-left: 0!important;
          }
          .provideTags {
            padding-left: 0!important;
          }
        }
      }
      h3 {
        font-size: 56px;
        margin: 0;
        width: 50%;
        padding-left: 0;
        padding-top: 0;
      }
      .text-tags {
        width: 50%;
        .provideText {
          font-size: 18px;
          padding-bottom: 0;
        }
        .provideTags {
          font-size: 18px;
          color: #ADADAD;
        }
      }
    }
  }
  .columns{
    .provideImage {
        min-width: 180px;
      }
    .column{
      @media screen and (max-width:767px) {
      }

    }
  }
`