import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"


export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  margin-top: -8rem;
  @media screen and (max-width:767px) {
    height: 250px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  background: #0D0628;
  color: #fff;
  margin-top: 4rem;
  padding: 4rem 0;
  @media screen and (max-width: 767px) {
    padding: 4rem 0 1.5rem;
  }
  .columns {
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
    .column{
      :nth-child(1) {
        max-width: 40%;
        @media screen and (max-width: 767px) {
          max-width:100%;
          text-align: center;
          padding-left: 0;
          padding-right: 0;
        }
      }
      :nth-child(2) {
        max-width: 40%;
        @media screen and (max-width: 767px) {
          max-width:100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .btn {
    background: #42E2B8;
  }
  p {
    font-size: 18px;
    line-height: 1.6em;
  }
`