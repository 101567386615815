import React from "react"
import { Wrapper, StyledImg } from "./Story.styles"
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image"
import { useStoryQuery } from "../../../hooks/useStoryQuery"

const Story = () => {

  const {
    wpPage: { ACF_Homepage: data },
  } = useStoryQuery()

  const imageData = getImage(data.storyImage.localFile)
  const storyImageAltText = data.storyImage.altText
  const storyText = data.storyText
  const storyButtonTitle = data.storyButton.title
  const storyButtonUrl = data.storyButton.url

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
          <div className="column">
            <StyledImg image={imageData} 
            alt={storyImageAltText}
            />
          </div>
          <div className="column">
            <div className="story-text" dangerouslySetInnerHTML={{ __html: storyText }} />
            <Link to={storyButtonUrl} className="btn">
              <span dangerouslySetInnerHTML={{ __html: storyButtonTitle }} />
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Story
