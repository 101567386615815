import React from "react"
import { Wrapper, StyledImg } from "./Provide.styles"
import { getImage } from "gatsby-plugin-image"
import { useProvideQuery } from "../../../hooks/useProvideQuery"

const Provide = () => {

  const {
    wpPage: { ACF_Homepage: data },
  } = useProvideQuery()

  const provideText = data.provideText

  const provide1Image = getImage(data.provide1Image.localFile)
  const provide1ImageAltText = data.provide1Image.altText
  const provide1Tags = data.provide1Tags
  const provide1Text = data.provide1Text
  const provide1Title = data.provide1Title

  const provide2Image = getImage(data.provide2Image.localFile)
  const provide2ImageAltText = data.provide2Image.altText
  const provide2Tags = data.provide2Tags
  const provide2Text = data.provide2Text
  const provide2Title = data.provide2Title

  const provide3Image = getImage(data.provide3Image.localFile)
  const provide3ImageAltText = data.provide3Image.altText
  const provide3Tags = data.provide3Tags
  const provide3Text = data.provide3Text
  const provide3Title = data.provide3Title



  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="provideMainText column" dangerouslySetInnerHTML={{ __html: provideText }} />
        </div>
        <div className="columns provideRow">
            <div className="provideImage provide1Image column">
            <StyledImg 
              image={provide1Image} 
              alt={provide1ImageAltText} 
              objectFit="contain"
              objectPosition="left"
              />
            </div>
            <div className="columns title-text-tags-wrapper">
                <h3 className="provide1Title column" dangerouslySetInnerHTML={{ __html: provide1Title }} />
                <div className="text-tags">
                    <div className="provideText provide1Text column" dangerouslySetInnerHTML={{ __html: provide1Text }} />
                    <div className="provideTags provide1Tags column" dangerouslySetInnerHTML={{ __html: provide1Tags }} />
                </div>
            </div>
        </div>
        <div className="columns provideRow">
            <div className="provideImage provide2Image column">
            <StyledImg 
              image={provide2Image} 
              alt={provide2ImageAltText} 
              objectFit="contain"
              objectPosition="left"
              />
            </div>
            <div className="columns title-text-tags-wrapper">
                <h3 className="provide2Title column" dangerouslySetInnerHTML={{ __html: provide2Title }} />
                <div className="text-tags">
                    <div className="provideText provide2Text column" dangerouslySetInnerHTML={{ __html: provide2Text }} />
                    <div className="provideTags provide2Tags column" dangerouslySetInnerHTML={{ __html: provide2Tags }} />
                </div>
            </div>
        </div>
        <div className="columns provideRow">
            <div className="provideImage provide3Image column">
            <StyledImg 
              image={provide3Image} 
              alt={provide3ImageAltText} 
              objectFit="contain"
              objectPosition="left"
              />
            </div>
            <div className="columns title-text-tags-wrapper">
                <h3 className="provide3Title column" dangerouslySetInnerHTML={{ __html: provide3Title }} />
                <div className="text-tags">
                    <div className="provideText provide3Text column" dangerouslySetInnerHTML={{ __html: provide3Text }} />
                    <div className="provideTags provide3Tags column" dangerouslySetInnerHTML={{ __html: provide3Tags }} />
                </div>
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Provide
