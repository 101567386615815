import React from "react"
import { Wrapper, StyledImg } from "./Three.styles"
import { getImage } from "gatsby-plugin-image"
import { useThreeQuery } from "../../../hooks/useThreeQuery"
import { motion } from "framer-motion";
import ThreeImage from '../../../images/3.svg';

const Three = () => {
  
  const {
    wpPage: { ACF_Homepage: data },
  } = useThreeQuery()

  const threeLogo = getImage(data.threeLogo.localFile)
  const imageData = getImage(data.threeImage.localFile)
  const threeText = data.threeText
  const threeLogoAltText = data.threeLogo.altText
  const threeImageAltText = data.threeImage.altText


  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="heading">
              <StyledImg 
              image={threeLogo} 
              alt={threeLogoAltText} 
              objectFit="contain"
              />
            </div>
            <div className="text-section">
              <div className="three-text" dangerouslySetInnerHTML={{ __html: threeText }} />
              <ul>
                {data.threeList.map( (description, i) => (
                    <li key={i}>{description.listItem}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="color-mask">
            <motion.div className="color-mask-layer" 
            initial={{ transform: 'scaleX(0.5)' }}
            whileInView={{ transform: 'scaleX(1)' }}

            />
            <StyledImg image={imageData} 
            alt={threeImageAltText} 
            />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Three
