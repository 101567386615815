import styled from "styled-components"

import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  padding: 6rem 0 1rem;
  @media screen and (max-width:767px) {
    padding: 3rem 0 1rem;
  }
  .columns{
    .column{
      @media screen and (max-width:767px) {
      }
    }
    .left-col {
      .gatsby-image-wrapper {
        @media screen and (max-width: 991px) {
          position: sticky;
          top: 105px;
        }
      }
    }

    .right-col {
      h1 {
        line-height: 1em;
        margin: 0 0 0.5rem;
        @media (min-width:768px) {
          font-size: 52px;
        }
      }
      p {
        font-size: 18px;
        line-height: 1.6em;
      }
      .columns{
        flex-wrap: wrap;
        .column{
          min-width: calc(50% - 1.5rem);
          max-width: calc(50% - 1.5rem);
          padding-bottom: 0;
          @media screen and (max-width: 767px) {
            min-width: 100%!important;
            max-width: 100%!important;
          }
          h2 {
            margin: 0 0 0.5rem;
            font-size: 36px;
            font-family: 'Teko',sans-serif;
            font-weight: 700;
            @media screen and (max-width: 767px) {
              font-size: 24px;
            }
          }
          h3 {
            margin: 0 0 0.5rem;
          }
        }
      }
    }
  }
`