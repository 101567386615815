import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  img {
  }
`

export const Wrapper = styled.div`
  position: relative;
  padding: 6rem 0;
  @media screen and (max-width:767px) {
    padding: 2.25rem 0;
  }

.swiper {
  width: 100%;
}

.swiper-slide {
    height: auto;
    .slide-inner {
        padding: 1.5rem;
        border: solid 1px #0D0628;
        background: #fff;
        /*height: 100%;*/
        h2 {
            margin: 1.25rem 0 0.75rem;
        }
        p {
            font-size: 18px;
            line-height: 1.6em;
            color: #000;
        }
        p.testimony-name {
          margin-bottom: 0!important;
          font-weight: bold;
          color: #000;
        }
        .gatsby-image-wrapper {
            margin-bottom: 1.5rem;
            max-width: 256px;
            img {
              object-fit: contain!important;
              object-position: left;

          }
        }
    }
}


  .extension-image {
    .columns{
      margin: 0;
      .column {
        padding: 0;
      }
    }
  }
  .extension-text{
    max-width: 1000px;
    .columns{
      margin-right: 0;
      margin-left: 0;
      @media screen and (max-width:767px) {
        display: block;
      }
      .column{
        padding-top: 4.5rem;
        width: 50%;
        @media screen and (max-width:767px) {
          margin: 0;
          max-width: 100%;
          padding: 2.75rem 0 0;
          width: 100%;
        }
        h2 {
          margin-bottom: 0.5rem;
        }
        p {
          line-height: 1.6em;
          font-size: 18px;
          margin-bottom: 1rem;
        }
      }
    }
  }
`