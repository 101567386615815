import { useStaticQuery, graphql } from "gatsby"

export const useStoryQuery = () => {
    const data = useStaticQuery(graphql`
    query StoryQuery {
      wpPage(databaseId: {eq: 185}) {
        id
        ACF_Homepage {
          storyImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          storyText
          storyButton {
            target
            title
            url
          }
        }
      }
    }
  `)

  return data
}
