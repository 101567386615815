import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import HeroMask from "./HeroMask"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  img {
    -webkit-mask-image: url(${HeroMask});
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top center;
  }
`

export const Wrapper = styled.div`
  position: relative;
`
