import React from "react"
import { Wrapper, StyledImg } from "./One.styles"
import { getImage } from "gatsby-plugin-image"
import { useOneQuery } from "../../../hooks/useOneQuery"
import { motion } from "framer-motion";
import OneImage from '../../../images/1.svg';

const One = () => {

  const {
    wpPage: { ACF_Homepage: data },
  } = useOneQuery()

  const oneLogo = getImage(data.oneLogo.localFile)
  const oneLogoAltText = data.oneLogo.altText
  const imageData = getImage(data.oneImage.localFile)
  const oneImageAltText = data.oneImage.altText
  const oneText = data.oneText


  return (

    <Wrapper>
      <div className="container">
      <div className="columns heading-section">
          <div className="column">
            <h2>We deliver</h2>
          </div> 
      </div>
        <div className="columns">
          <div className="column">
            <div className="heading">
              <StyledImg 
              image={oneLogo} 
              alt={oneLogoAltText} 
              objectFit="contain"
              />
            </div>
            <div className="text-section">
            <div className="one-text" dangerouslySetInnerHTML={{ __html: oneText }} />
              <ul>
              {data.oneList.map( (description, i) => (
                  <li key={i}>{description.listItem}</li>
              ))}
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="color-mask">
            <motion.div className="color-mask-layer" 
            //initial={{ x: 600 }} 
            // animate={{ x: 0 }} 
            // transition={spring}
            //whileInView={{ x: 0 }}
            initial={{ transform: 'scaleX(0.5)' }}
            whileInView={{ transform: 'scaleX(1)' }}

            />
            <StyledImg image={imageData} alt={oneImageAltText} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default One
