import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"


export const StyledImg = styled(GatsbyImage)`
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  padding: 0;
  > .container {
    > .columns {
      @media screen and (max-width:767px) {
        flex-direction: column;
        margin: 0;
      }
      .column{
        :nth-child(1) {
          max-width: 45%;
          @media screen and (max-width:767px) {
            margin: auto;
          }
        }
        :nth-child(2) {
          max-width: 45%;
          margin-left: 10%;
          @media screen and (max-width:767px) {
            max-width: 100%;
            padding: 0;
            margin-left: 0;
          }
        }
      }
      .heading {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        padding-top: 4.5rem;
        @media screen and (max-width: 767px) {
          margin-top: 1.5rem;
          padding-top: 0;
        }
        img{
          :nth-child(1) {
            max-width: 40%;
            width: 274px;
          }
          :nth-child(2) {
            /* margin-left: 2.5rem; */
            object-position: left;
            max-width: calc(100% - 2.5rem);
            @media screen and (max-width:767px) {
              width: 100%;
            }
          }
        }
      }
      .text-section {
        p {
          font-size: 14px;
          line-height: 1.6em;
          margin: 0 0 0.75rem;
        }
        ul{
          color: #B0A9B7;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          margin-left: -0.75rem;
          margin-right: -0.75rem;
          li {
            font-weight: 100;
            font-size: 18px;
            margin-bottom: 0.25rem;
            width: calc(50% - 1.5rem);
            padding: 0 0.75rem;
          }
        }
      }
    }
  }
  .color-mask {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .color-mask-layer {
      background: #0095F2;
      height: 250px;
      display: block;
      position: absolute;
      mix-blend-mode: multiply;
      z-index: 2;
      right: 70%;
      width: 100vw;
      transition: transform 0.2s linear;
      @media screen and (max-width: 767px) {
        height: 15vw;
        margin-top: 2rem;
        right: auto;
        width: 100vw;
        left: 0;
        transform-origin: left;
        transition: transform 0.25s linear;
      }
    }
  }
`