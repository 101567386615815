import { useStaticQuery, graphql } from "gatsby"

export const useProvideQuery = () => {
    const data = useStaticQuery(graphql`
    query ProvideQuery {
        wpPage(databaseId: {eq: 185}) {
          id
          ACF_Homepage {
            provide1Tags
            provide1Text
            provide1Title
            provide2Tags
            provide2Text
            provide2Title
            provide3Tags
            provide3Text
            provide3Title
            provideText
            provide1Image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            provide2Image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            provide3Image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      
  `)

  return data
}
