import React from "react"
import { Wrapper, StyledImg } from "./Strategy.styles"
import { getImage } from "gatsby-plugin-image"
import { useStrategyQuery } from "../../../hooks/useStrategyQuery"

const Strategy = () => {

  const {
    wpPage: { ACF_Homepage: data },
  } = useStrategyQuery()
  const mainText = data.mainText
  const firstColumn = data.firstColumn
  const secondColumn = data.secondColumn
  const thirdColumn = data.thirdColumn
  const strategyImage = getImage(data.strategyImage.localFile)
  const strategyImageAltText = data.strategyImage.altText


  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column left-col">
            <StyledImg 
              image={strategyImage} 
              alt={strategyImageAltText} 
              objectFit="contain"
              objectPosition="left"
              />
            </div>
            <div className="column right-col">
                <div className="mainText" dangerouslySetInnerHTML={{ __html: mainText }} />
                <div className="columns">
                    <div className="firstColumn column" dangerouslySetInnerHTML={{ __html: firstColumn }} />
                    <div className="secondColumn column" dangerouslySetInnerHTML={{ __html: secondColumn }} />
                    <div className="thirdColumn column" dangerouslySetInnerHTML={{ __html: thirdColumn }} />
                </div>
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Strategy
