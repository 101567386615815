import { useStaticQuery, graphql } from "gatsby"

export const useExtensionQuery = () => {
  const data = useStaticQuery(graphql`
  query ExtensionQuery {
    wpPage(databaseId: {eq: 185}) {
      id
      ACF_Homepage {
        teamImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
          altText
        }
        teamText
        teamButton {
          url
          title
          target
        }
        testimonials {
          name
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
  
  `)

  return data
}
