import React from "react"
import { Wrapper, StyledImg } from "./Extension.styles"
import { Link } from "gatsby";
import { useExtensionQuery } from "../../../hooks/useExtensionQuery"
import { getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Extension = () => {

  const {
    wpPage: { ACF_Homepage: data },
  } = useExtensionQuery()

  const imageData = getImage(data.teamImage.localFile)
  const teamImageAltText = data.teamImage.altText
  const teamText = data.teamText
  const teamButtonTitle = data.teamButton.title
  const teamButtonUrl = data.teamButton.url

  return (
    <Wrapper>
      <div className="extension-image container">
        <div className="columns">
          <div className="column">
            <StyledImg image={imageData} 
            alt={teamImageAltText}
            />
          </div>
        </div>
      </div>
      <div className="extension-text container">
        <div className="columns">
          <div className="column">
            <div className="extension-text" dangerouslySetInnerHTML={{ __html: teamText }} />
            <Link to={teamButtonUrl} className="btn">
              <span dangerouslySetInnerHTML={{ __html: teamButtonTitle }} />
            </Link>
          </div>
          <div className="column">
          <Swiper 
          className="swiper"
          spaceBetween={20}
          slidesPerView={1}
          navigation
          pagination={{
            clickable: true,
          }}
      >

            {data.testimonials.map( (description, i) => (
                <SwiperSlide key={i}>
                    <div className="slide-inner">
                        <StyledImg image={description.image.localFile.childImageSharp.gatsbyImageData} alt={description.image.altText} />
                        <p className="testimony-text">{description.text}</p>
                        <p className="testimony-name">
                            {description.name}
                        </p>
                    </div>
                </SwiperSlide>
              ))}
          </Swiper>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Extension
