import { useStaticQuery, graphql } from "gatsby"

export const useStrategyQuery = () => {
    const data = useStaticQuery(graphql`
    query StrategyQuery {
        wpPage(databaseId: {eq: 185}) {
          id
          ACF_Homepage {
            firstColumn
            secondColumn
            thirdColumn
            mainText
            strategyImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      
  `)

  return data
}
