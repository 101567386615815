import React from 'react';
import Hero from "../components/Home/Hero/Hero"
import Strategy from '../components/Home/Strategy/Strategy';
import Provide from '../components/Home/Provide/Provide';
import One from '../components/Home/One/One';
import Two from '../components/Home/Two/Two';
import Three from '../components/Home/Three/Three';
import Story from '../components/Home/Story/Story';
import Extension from '../components/Home/Extension/Extension';
import LetsChat from '../components/Home/LetsChat/LetsChat';


import Layout from "../components/Layout/Layout"
import Seo from '../components/Seo/Seo';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <Strategy />
    <Provide />
    <One />
    <Two />
    <Three />
    <Story />
    <Extension />
    <LetsChat />
  </Layout>
)


export default IndexPage;
