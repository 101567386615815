import React from "react"
import { Wrapper, StyledImg } from "./Two.styles"
import { useTwoQuery } from "../../../hooks/useTwoQuery"
import { getImage } from "gatsby-plugin-image"
import { motion } from "framer-motion";
import TwoImage from '../../../images/2.svg';

const Two = () => {

  const {
    wpPage: { ACF_Homepage: data },
  } = useTwoQuery()

  const imageData = getImage(data.twoImage.localFile)
  const twoLogo = getImage(data.twoLogo.localFile)
  const twoText = data.twoText
  const twoLogoAltText = data.twoLogo.altText
  const twoImageAltText = data.twoImage.altText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
        <div className="column">
            <div className="color-mask">
            <motion.div className="color-mask-layer" 
            initial={{ transform: 'scaleX(0.5)' }}
            whileInView={{ transform: 'scaleX(1)' }}

            />
            <StyledImg image={imageData} 
            alt={twoImageAltText} 
            />
            </div>
          </div>
          <div className="column">
            <div className="heading">
              <StyledImg 
              image={twoLogo} 
              alt={twoLogoAltText} 
              objectFit="contain"
              />
            </div>
            <div className="text-section">
              <div className="two-text" dangerouslySetInnerHTML={{ __html: twoText }} />
              <ul>
                {data.twoList.map( (description, i) => (
                    <li key={i}>{description.listItem}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Two
