import { useHeroQuery } from "../../../hooks/useHeroQuery"

const HeroMask = () => {
  
  const {
    wpPage: { ACF_Homepage: data },
  } = useHeroQuery()

  const imageMask = data.heroMask.localFile.publicURL


  return (
    imageMask
  )
}

export default HeroMask
