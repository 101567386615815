import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useHeroQuery } from "../../../hooks/useHeroQuery"
import { Wrapper, StyledImg } from "./Hero.styles"

const Hero = () => {
  

  const {
    wpPage: { ACF_Homepage: data },
  } = useHeroQuery()

  const imageData = getImage(data.hero.localFile)
  const heroAltText = data.hero.altText


  return (
    <Wrapper>
      <div className="container">
        <StyledImg id="hero-image-about" image={imageData} alt={heroAltText} />
      </div>
    </Wrapper>
  )
}

export default Hero
